import React from 'react';
import './index.css';
import Logo from './logo.png';

const AppMigrated: React.FC = () => {
  return (
    <div className='new-container'>
      <div className='inner-container'>
        <img src={Logo} style={{ width: '200px', marginBottom: '30px' }} alt='Shyft Logo' />
        <p style={{ fontSize: 'larger', textAlign: 'center' }}>
          Connect has migrated, please login
          <a style={{ color: 'blue', marginLeft: '5px' }} href='https://flexview.shyftpower.com'>
            here
          </a>
        </p>
        <p style={{ textAlign: 'center', fontSize: '14px' }}>
          If you have trouble logging in, please contact the Customer Success Manager by sending an email to
          <a style={{ color: 'blue', marginLeft: '5px' }} href='mailto:support@shyftpower.com.'>
            support@shyftpower.com.
          </a>
        </p>
      </div>
    </div>
  );
};

export default AppMigrated;
